// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-smart-validator-js": () => import("./../../../src/pages/smart-validator.js" /* webpackChunkName: "component---src-pages-smart-validator-js" */),
  "component---src-pages-terms-of-services-js": () => import("./../../../src/pages/terms-of-services.js" /* webpackChunkName: "component---src-pages-terms-of-services-js" */),
  "component---src-pages-virtual-assistant-js": () => import("./../../../src/pages/virtual-assistant.js" /* webpackChunkName: "component---src-pages-virtual-assistant-js" */),
  "component---src-pages-what-is-ip-js": () => import("./../../../src/pages/what-is-ip.js" /* webpackChunkName: "component---src-pages-what-is-ip-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

