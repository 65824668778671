import mergeAll from "lodash/fp/mergeAll"
import { theme as presetTheme } from "@chakra-ui/core"

const customTheme = {
  colors: {
    "theme-dark": "#262262",
    "theme-purple": "#9A258F",
    "theme-grey": "#939598",
    "theme-green": "#39B54A",
  },
  fonts: {
    body: '"Gotham", sans-serif',
    heading: '"Gotham", sans-serif',
  },
  fontWeights: {
    heading: "600",
  },
}

const theme = mergeAll([presetTheme, customTheme])

export default theme
